<template>
  <h2 class="summary-heading">Valgte bookinger:</h2>
  <ul>
    <li v-for="b in chosenBookings" :key="b">
      {{ b.date }} kl. {{ b.time }} - {{ b.field }}
    </li>
  </ul>
  <div id="price">
    <strong id="price-label">Pris:</strong>
    <p id="price-value">{{ price }}</p>
  </div>
</template>
<script lang="ts">
import BookingViewModel from "@/models/bookingViewModel";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "BookingSummary",
  setup() {
    const store = useStore();

    const times = [
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
    ];

    const chosenBookings = computed(() => {
      return store.getters.getChosenBookings;
    });

    const price = computed(() => {
      let price = 0;

      let bookings: BookingViewModel[];
      bookings = [];
      for (let i = 0; i < chosenBookings.value.length; i++) {
        if (chosenBookings.value[i].field === "Klubbhus") {
          return "Pga klubbhuset, må prisen avtales med Finstadbru Hundesport.";
        }
        if (chosenBookings.value[i].time.includes("-")) {
          const timesplit = chosenBookings.value[i].time.split(" - ");
          const fromTime = timesplit[0];
          const toTime = timesplit[1];

          let bookingTimes: string[];
          bookingTimes = [];
          // lager array med valgte tider
          times.forEach((time) => {
            if (time >= fromTime && time < toTime) {
              bookingTimes.push(time);
            }
          });

          for (let j = 0; j < bookingTimes.length; j++) {
            let booking = {
              date: chosenBookings.value[i].date,
              originalTime: bookingTimes[j],
            } as BookingViewModel;

            bookings.push(booking);
          }
        } else {
          let booking = {
            date: chosenBookings.value[i].date,
            originalTime: chosenBookings.value[i].time,
          } as BookingViewModel;

          bookings.push(booking);
        }
      }

      for (let i = 0; i < bookings.length; i++) {
        const dateSplit = bookings[i].date.split(":");
        const day = parseInt(dateSplit[0]);
        const month = parseInt(dateSplit[1]) - 1;
        const year = parseInt(dateSplit[2]);
        const date = new Date(year, month, day);
        const weekday = date.getDay();

        if (weekday === 0 || weekday === 6) {
          price += 100;
        } else {
          const timeSplit = bookings[i].originalTime.split(":");
          const hour = parseInt(timeSplit[0]);
          if (hour < 17) {
            price += 75;
          } else {
            price += 100;
          }
        }
      }
      return price + ",-";
    });

    return {
      chosenBookings,
      price,
    };
  },
});
</script>
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
#price-value {
  display: inline-block;
  margin-left: 0.5%;
}
</style>
