<template>
  <h1 class="page-title">Finstadbru Hundesport Booking</h1>
  <div class="back-button">
    <button class="btn" @click="toBooking()">
      <back theme="filled" size="24" fill="#2c3e50" />
      <strong class="button-text" id="back">Tilbake</strong>
    </button>
  </div>
  <div class="close-button">
    <button class="btn" @click="toFrontpage()">
      <strong class="button-text" id="close">Avbryt</strong>
      <close theme="filled" size="22" fill="#2c3e50" />
    </button>
  </div>
  <div v-if="isBookings">
    <div class="information">
      <h2>Vennlist les før du går videre:</h2>
      <p>
        Bookingen fungerer slik at dine valgte bookinger blir reservert når du
        fullfører orderen. Vi vil få beskjed, og behandler reservasjonen så
        raskt som mulig. Du vil få en mail med informasjon om hvordan du betaler
        så fort orderen er godkjent. Etter betaling er bookingen bindene fra
        begge parter. Inntil bookingen er godkjent og betalt, kan begge parter
        si opp bookingen.
      </p>
    </div>
    <booking-summary />
    <checkout-form />
  </div>
  <div v-else>
    <h2>Wups, noe har gått galt!</h2>
    <p>Gå tilbake for å velge tider du vil booke, eller avbryt.</p>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import BookingSummary from "../../components/checkout/BookingSummary.vue";
import CheckoutForm from "../../components/checkout/CheckoutForm.vue";
import { Back, Close } from "@icon-park/vue-next";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Checkout",
  components: { BookingSummary, CheckoutForm, Back, Close },
  setup() {
    const router = useRouter();
    const store = useStore();

    const trigger = ref(""); //made only to fetch chosenBookings onMounted
    const isBookings = ref(true);

    const toBooking = () => {
      router.push({ path: "/fhbooking/booking" });
    };
    const toFrontpage = () => {
      store.commit("removeAllBookings");
      router.push({ path: "/fhbooking" });
    };

    const chosenBookings = computed(() => {
      return store.getters.getChosenBookings;
    });

    watch([chosenBookings.value, trigger], () => {
      if (chosenBookings.value.length >= 1) {
        isBookings.value = true;
      } else {
        isBookings.value = false;
      }
    });

    onMounted(() => {
      trigger.value = "f";
    });

    return {
      toBooking,
      toFrontpage,
      isBookings,
    };
  },
});
</script>

<style scoped>
.pag-title {
  margin-bottom: 5%;
}
button {
  background-color: rgba(255, 0, 0, 0);
  width: 120px;
}
.back-button,
.close-button {
  display: inline-block;
  margin: 0 3% 3% 3%;
}
.back-button {
  text-align: left;
}
.button-text {
  display: inline-block;
  vertical-align: middle;
}
#back {
  margin-left: 5%;
}
#close {
  margin-right: 5%;
}
.information {
  width: 50%;
  margin: 0 auto;
  padding-bottom: 1.5rem;
}
@media screen and (max-width: 768px) {
  .information {
    width: 90%;
  }
}
</style>
