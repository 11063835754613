
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import firebase from "firebase";
import emailjs from "emailjs-com";
import BookingViewModel from "@/models/bookingViewModel";
import ChooseBookingsVue from "../booking/ChooseBookings.vue";

export default defineComponent({
  name: "CheckoutForm",
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const database = firebase.database();

    const formData = reactive({
      name: "",
      email: "",
      tlf: "",
      info: "",
      terms: false,
    });

    const times = [
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
    ];

    const validName = ref(false);
    const validEmail = ref(false);
    const validTlf = ref(false);
    const validInfo = ref(true);
    const validTerms = ref(false);
    const formReady = ref(false);

    watch([formData], () => {
      const emailRegx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;
      const tlfRegex = /^\d{8}$/;

      if (formData.name.length > 5) {
        validName.value = true;
      } else {
        validName.value = false;
      }

      if (emailRegx.test(formData.email)) {
        validEmail.value = true;
      } else {
        validEmail.value = false;
      }

      if (tlfRegex.test(formData.tlf)) {
        validTlf.value = true;
      } else {
        validTlf.value = false;
      }

      if (formData.info.length < 200) {
        validInfo.value = true;
      } else {
        validInfo.value = false;
      }

      if (formData.terms) {
        validTerms.value = true;
      } else {
        validTerms.value = false;
      }

      if (
        validName.value &&
        validEmail.value &&
        validTlf.value &&
        validInfo.value &&
        validTerms.value
      ) {
        formReady.value = true;
      } else {
        formReady.value = false;
      }
    });

    const chosenBookings = computed(() => {
      return store.getters.getChosenBookings;
    });

    const checkChoosenBookingStatus = async (chosenBookings: any) => {
      let bookingsAvailable = true;

      for (let i = 0; i < chosenBookings.length; i++) {
        let bookingTimes: string[];
        bookingTimes = [];
        if (chosenBookings[i].time.includes("-")) {
          const timesplit = chosenBookings[i].time.split(" - ");
          const fromTime = timesplit[0];
          const toTime = timesplit[1];

          // lager array med valgte tider
          times.forEach((time) => {
            if (time >= fromTime && time < toTime) {
              bookingTimes.push(time);
            }
          });
        } else {
          bookingTimes.push(chosenBookings[i].time);
        }
        for (let j = 0; j < bookingTimes.length; j++) {
          await database
            .ref("all_bookings")
            .child(chosenBookings[i].date)
            .child(chosenBookings[i].field)
            .child(bookingTimes[j])
            .get()
            .then((snapshot) => {
              if (snapshot.exists()) {
                if (snapshot.val().status !== "Ledig") {
                  if (bookingsAvailable) {
                    // Fordi ellers fyrer den av flere ganger
                    toast.error(
                      "Bookingen av " +
                        chosenBookings[i].field +
                        " den " +
                        chosenBookings[i].date +
                        " kl. " +
                        bookingTimes[j] +
                        " er dessverre blitt utilgjengelig. Gå tilbake for å endre bookingen før du fortsetter.",
                      { timeout: 10000 }
                    );
                  }
                  bookingsAvailable = false;
                }
              } else {
                bookingsAvailable = false;
                toast.error(
                  "Sendingen feilet! Prøv igjen og ta kontakt om feilen vedvarer."
                );
                // console.log(
                //   "Fant ikke bookingen i databasen. SendOrder i CheckoutForm."
                // );
              }
            })
            .catch(() => {
              bookingsAvailable = false;
              // console.error(
              //   "Henting av booking fra databasen for å sjekke tilgjengelighet feilet.",
              //   error
              // );
              toast.error(
                "Sendingen feilet! Prøv igjen og ta kontakt om feilen vedvarer."
              );
            });
        }
      }

      if (bookingsAvailable) {
        return true;
      } else {
        return false;
      }
    };

    const sendOrder = async () => {
      let mailSent = false;
      let allBookingsUpdated = false;
      let databaseUpdated = false;

      // Sjekker om bookingene fremdeles er tilgjengelige
      const bookingsAvailable = await checkChoosenBookingStatus(
        chosenBookings.value
      );

      // Hvis alle bookinger er tilgjengelige blir det sendt ut en mail til Hilde og Bjørn
      if (bookingsAvailable) {
        try {
          await emailjs
            .send(
              "service_m0qsppw",
              "template_fhbooking",
              formData,
              "user_IWxZlMfhRHN2PM5uesr8K"
            )
            .then(
              (result) => {
                console.log("SUCCESS!", result.status, result.text);
                mailSent = true;
              },
              (error) => {
                console.log("Sending av mail feilet", error);
                toast.error(
                  "Sendingen feilet! Prøv igjen og ta kontakt om feilen vedvarer."
                );
              }
            );
        } catch (error) {
          console.log("Sending av mail gikk i catchen", error);
          toast.error(
            "Sendingen feilet! Prøv igjen og ta kontakt om feilen vedvarer."
          );
        }

        // Oppdaterer databasen
        if (mailSent) {
          for (let i = 0; i < chosenBookings.value.length; i++) {
            let bookingTimes: string[];
            bookingTimes = [];
            if (chosenBookings.value[i].time.includes("-")) {
              const timesplit = chosenBookings.value[i].time.split(" - ");
              const fromTime = timesplit[0];
              const toTime = timesplit[1];

              // lager array med valgte tider
              times.forEach((time) => {
                if (time >= fromTime && time < toTime) {
                  bookingTimes.push(time);
                }
              });
            } else {
              bookingTimes.push(chosenBookings.value[i].time);
            }
            for (let j = 0; j < bookingTimes.length; j++) {
              await database
                .ref("all_bookings")
                .child(chosenBookings.value[i].date)
                .child(chosenBookings.value[i].field)
                .child(bookingTimes[j])
                .update({
                  status: "Reservert",
                  name: formData.name,
                  email: formData.email,
                  tlf: formData.tlf,
                  otherInfo: formData.info,
                  bookingTime: chosenBookings.value[i].time,
                })
                .then(() => {
                  allBookingsUpdated = true;
                })
                .catch((error) => {
                  console.error(
                    "Oppdatering av allBookings tabellen gikk i catchen",
                    error
                  );
                  toast.error(
                    "Sendingen feilet! Prøv igjen og ta kontakt om feilen vedvarer."
                  );
                });
            }

            if (allBookingsUpdated) {
              let bookingsOnHoldID =
                chosenBookings.value[i].date +
                "_" +
                chosenBookings.value[i].field +
                "_" +
                chosenBookings.value[i].time;
              await database
                .ref("bookings_on_hold")
                .child(bookingsOnHoldID)
                .set({
                  date: chosenBookings.value[i].date,
                  field: chosenBookings.value[i].field,
                  time: chosenBookings.value[i].time,
                  status: "Reservert",
                  name: formData.name,
                  email: formData.email,
                  tlf: formData.tlf,
                  otherInfo: formData.info,
                })
                .then(() => {
                  databaseUpdated = true;
                })
                .catch((error) => {
                  console.error(
                    "Oppdatering av bookingsOnHold tabellen gikk i catchen",
                    error
                  );
                  toast.error(
                    "Sendingen feilet! Prøv igjen og ta kontakt om feilen vedvarer."
                  );
                });
            }
          }
        }
        if (databaseUpdated) {
          router.push({ path: "/fhbooking/booking/confirmation" });
          store.commit("removeAllBookings");
        }
      }
    };

    return {
      formData,
      validName,
      validEmail,
      validTlf,
      validInfo,
      validTerms,
      formReady,
      sendOrder,
    };
  },
});
