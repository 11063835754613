
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import BookingSummary from "../../components/checkout/BookingSummary.vue";
import CheckoutForm from "../../components/checkout/CheckoutForm.vue";
import { Back, Close } from "@icon-park/vue-next";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Checkout",
  components: { BookingSummary, CheckoutForm, Back, Close },
  setup() {
    const router = useRouter();
    const store = useStore();

    const trigger = ref(""); //made only to fetch chosenBookings onMounted
    const isBookings = ref(true);

    const toBooking = () => {
      router.push({ path: "/fhbooking/booking" });
    };
    const toFrontpage = () => {
      store.commit("removeAllBookings");
      router.push({ path: "/fhbooking" });
    };

    const chosenBookings = computed(() => {
      return store.getters.getChosenBookings;
    });

    watch([chosenBookings.value, trigger], () => {
      if (chosenBookings.value.length >= 1) {
        isBookings.value = true;
      } else {
        isBookings.value = false;
      }
    });

    onMounted(() => {
      trigger.value = "f";
    });

    return {
      toBooking,
      toFrontpage,
      isBookings,
    };
  },
});
